<template>
    <v-dialog
        persistent
        v-model="deleteDialog"
        transition="dialog-bottom-transition"
        overlay=false
        scrollable
        width="500px"
        min-width="400px"
        max-width="500px"
    >
        <v-card>
            <v-card-title class="error white--text">{{ $t('message.deleteBundle') + ": " + bundleNo }}</v-card-title>
            <v-card-text>
                <v-form lazy-validation ref="deleteBundleForm" v-model="validForm">
                    <div class="mt-3 h5">{{ $t('message.confirmations.continueDeleteBundle') }}</div>
                    <v-layout row mt-3 px-3>
                        <v-flex xs4 lg3 md4 py-1 class="align-center">{{ $t('message.deleteReason') }}</v-flex>
                        <v-flex xs8 lg9 md4 py-1>
                            <v-lazy>
                                <v-textarea
                                    :rules="[...validationRules.required, ...validationRules.plainTextWithNumberAndSymbols]"
                                    :value="delete_remark"
                                    autocomplete="off"
                                    auto-grow
                                    clearable
                                    dense
                                    hide-details="auto"
                                    rows="2"
                                    solo
                                    @change="delete_remark = $event"
                                />
                            </v-lazy>
                        </v-flex>
                    </v-layout>
                </v-form>
            </v-card-text>
            <v-card-actions class="pt-0">
                <v-btn color="default" small @click="deleteDialog = false" >{{$t('message.dismiss')}}</v-btn>
                <v-btn
                    :loading="loading.delete"
                    color="info"
                    small
                    @click="deleteBundle()"
                >{{ $t('message.proceed') }}</v-btn>
                <v-spacer/>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
import {isObject} from "Helpers/helpers";
import {mapActions, mapGetters} from "vuex";

export default {
    name: "DeleteBundle",
    props: ['bundleId','bundleNo','dialog','dialog-closed'],
    data(){
        return {
            delete_remark: null,
            delete_dialog: false,
            dialogs: {
                error:  false,
                error_message: ""
            },
            loading: {
                delete: false
            },
            validForm: true
        }
    },
    computed: {
        ...mapGetters([
            'validationRules'
        ]),
        deleteDialog: {
            get() {
                return this.delete_dialog;
            },
            set(value){
                this.delete_dialog = value
                if(value === false) {
                    this.$emit('dialog-closed')
                }
            }
        },
    },
    methods: {
        ...mapActions('stock',{
            deleteStockById: 'deleteStockById'
        }),
        deleteBundle() {
            if(this.$refs.deleteBundleForm.validate()) {
                const payload = {
                    stockId: this.bundleId,
                    deleteRemark: this.delete_remark
                }
                this.deleteStockById(payload)
                    .then((response) => {
                        if (response.status === 'success') {
                            this.$toast.success(this.$t('message.successes.bundleDeleted'),
                                {
                                    classes: ['icon-float-left'],
                                    icon: 'check_circle_outline'
                                }
                            )
                            this.loading.delete = false
                            this.delete_remark = false
                            this.$emit('delete-done')
                        } else {
                            if(isObject(response.message)){
                                //extract validation errors
                                const errors = Object.values(response.message)
                                errors.forEach(errorObj => {
                                    if (Array.isArray(errorObj)) {
                                        errorObj.forEach(error => {
                                            this.$toast.error(error,
                                                {
                                                    classes: ['icon-float-left'],
                                                    icon: 'error_outline'
                                                }
                                            )
                                        })
                                    } else {
                                        this.$toast.error(this.$t('message.errors.bundleNotDeleted'),
                                            {
                                                classes: ['icon-float-left'],
                                                icon: 'error_outline'
                                            }
                                        )
                                    }
                                })
                            } else {
                                this.$toast.error(this.$t('message.errors.bundleNotDeleted'),
                                    {
                                        classes: ['icon-float-left'],
                                        icon: 'error_outline'
                                    }
                                )
                            }
                            this.loading.delete = false
                        }

                    })
                    .catch(() => {
                        this.$toast.error(this.$t('message.errors.bundleNotDeleted'),
                            {
                                classes: ['icon-float-left'],
                                icon: 'error_outline'
                            }
                        )
                        this.loading.delete = false
                    })
            } else {
                this.$toast.error(this.$t('message.errors.bundleNotDeleted'),
                    {
                        classes: ['icon-float-left'],
                        icon: 'error_outline'
                    }
                )
                this.loading.delete = false
            }
        },
    },
    watch: {
        dialog(value) {
            this.delete_dialog = value
        }
    },
}
</script>

<style scoped>

</style>